<script>
import { lottery_record, longLine } from '@/api/beijing28' //引入开奖结果的接口
import { cloudBaseUrl } from '@/CONF.js'

export default {
    name: 'TrendsByLuzhu',
    components: {
        Header: () => import('components/base/Header'),
    },
    props: [
        'currentType',
    ],
    data() {
        var titles = {
            lhc: {
                drawingHistory: [getWord('number_of_period'), '1', '2', '3', '4', '5', '6', getWord('te')],
                currentRuleSize: 25,
                luzhu: [
                    { name: getWord(['zhengma', 'yi']), states: 1, num: 'num_one' },
                    { name: getWord(['zhengma', 'er']), states: 2, num: 'num_two' },
                    { name: getWord(['zhengma', 'san']), states: 3, num: 'num_three' },
                    { name: getWord(['zhengma', 'si']), states: 4, num: 'num_four' },
                    { name: getWord(['zhengma', 'wu']), states: 5, num: 'num_five' },
                    { name: getWord(['zhengma', 'liu']), states: 6, num: 'num_six' },
                    { name: getWord('item_tema'), states: 7, num: 'num_tema' }
                ],
            },
            pc28one: {
                drawingHistory: [getWord('number_of_period'), getWord('first_ball'), getWord('second_ball'), getWord('third_ball'), getWord('item_tema')],
                currentRuleSize: 5,
                luzhu: [
                    { name: getWord('first_ball'), states: 1, num: 'num_one' },
                    { name: getWord('second_ball'), states: 2, num: 'num_two' },
                    { name: getWord('third_ball'), states: 3, num: 'num_three' },
                    { name: getWord('item_tema'), states: 4, num: 'num_tema' }
                ]
            },
            fastthree: {
                drawingHistory: [getWord('number_of_period'), getWord('first_ball'), getWord('second_ball'), getWord('third_ball')],
                currentRuleSize: 3,
                luzhu: [
                    { name: getWord('first_ball'), states: 1, num: 'num_one' },
                    { name: getWord('second_ball'), states: 2, num: 'num_two' },
                    { name: getWord('third_ball'), states: 3, num: 'num_three' }
                ]
            },
            fc3d: {
                drawingHistory: [getWord('number_of_period'), getWord('first_ball'), getWord('second_ball'), getWord('third_ball')],
                currentRuleSize: 5,
                luzhu: [
                    { name: getWord('first_ball'), states: 1, num: 'num_one' },
                    { name: getWord('second_ball'), states: 2, num: 'num_two' },
                    { name: getWord('third_ball'), states: 3, num: 'num_three' }
                ]
            },
            ssc: {
                drawingHistory: [getWord('number_of_period'), getWord('first_ball'), getWord('second_ball'), getWord('third_ball'), getWord('fourth_ball'), getWord('fifth_ball')],
                currentRuleSize: 5,
                luzhu: [
                    { name: getWord('first_ball'), states: 1, num: 'num_one' },
                    { name: getWord('second_ball'), states: 2, num: 'num_two' },
                    { name: getWord('third_ball'), states: 3, num: 'num_three' },
                    { name: getWord('fourth_ball'), states: 4, num: 'num_four' },
                    { name: getWord('fifth_ball'), states: 5, num: 'num_five' }
                ]
            },
            tencent: {
                drawingHistory: [getWord('number_of_period'), getWord('first_ball'), getWord('second_ball'), getWord('third_ball'), getWord('fourth_ball'), getWord('fifth_ball')],
                currentRuleSize: 5,
                luzhu: [
                    { name: getWord('first_ball'), states: 1, num: 'num_one' },
                    { name: getWord('second_ball'), states: 2, num: 'num_two' },
                    { name: getWord('third_ball'), states: 3, num: 'num_three' },
                    { name: getWord('fourth_ball'), states: 4, num: 'num_four' },
                    { name: getWord('fifth_ball'), states: 5, num: 'num_five' }
                ]
            },
            sc: {
                drawingHistory: [getWord('number_of_period'), getWord('yi'), getWord('er'), getWord('san'), getWord('si'), getWord('wu'), getWord('liu'), getWord('qi'), getWord('ba'), getWord('jiu'), getWord('shi')],
                currentRuleSize: 6,
                luzhu: [
                    { name: getWord('first4'), states: 1, num: 'num_one' },
                    { name: getWord('second4'), states: 2, num: 'num_two' },
                    { name: getWord('third4'), states: 3, num: 'num_three' },
                    { name: getWord('fourth_place'), states: 4, num: 'num_four' },
                    { name: getWord('fifth_place'), states: 5, num: 'num_five' },
                    { name: getWord('sixth_place'), states: 6, num: 'num_six' },
                    { name: getWord('seventh_place'), states: 7, num: 'num_seven' },
                    { name: getWord('eight_place'), states: 8, num: 'num_eight' },
                    { name: getWord('ninth_place'), states: 9, num: 'num_nine' },
                    { name: getWord('tenth_place'), states: 10, num: 'num_ten' }
                ]
            },
            ttc: {
                drawingHistory: [getWord('number_of_period'), '1', '2', '3', '4', '5', '6', '7', '8', getWord(['item_tema', 'yi']), getWord(['item_tema', 'er'])],
                currentRuleSize: 18,
                luzhu: [
                    { name: getWord('first_ball'), states: 1, num: 'num_one' },
                    { name: getWord('second_ball'), states: 2, num: 'num_two' },
                    { name: getWord('third_ball'), states: 3, num: 'num_three' },
                    { name: getWord('fourth_ball'), states: 4, num: 'num_four' },
                    { name: getWord('fifth_ball'), states: 5, num: 'num_five' },
                    { name: getWord('sixth_ball'), states: 6, num: 'num_six' },
                    { name: getWord('seventh_ball'), states: 7, num: 'num_seven' },
                    { name: getWord('eighth_ball'), states: 8, num: 'num_eight' },
                    { name: getWord(['item_tema', 'yi']), states: 9, num: 'num_tema1' },
                    { name: getWord(['item_tema', 'er']), states: 10, num: 'num_tema2' },
                ]
            },
            gdklten: {
                drawingHistory: [getWord('number_of_period'), '1', '2', '3', '4', '5', '6', '7', '8'],
                currentRuleSize: 6,
                luzhu: [
                    { name: getWord('first_ball'), states: 1, num: 'num_one' },
                    { name: getWord('second_ball'), states: 2, num: 'num_two' },
                    { name: getWord('third_ball'), states: 3, num: 'num_three' },
                    { name: getWord('fourth_ball'), states: 4, num: 'num_four' },
                    { name: getWord('fifth_ball'), states: 5, num: 'num_five' },
                    { name: getWord('sixth_ball'), states: 6, num: 'num_six' },
                    { name: getWord('seventh_ball'), states: 7, num: 'num_seven' },
                    { name: getWord('eighth_ball'), states: 8, num: 'num_eight' },
                ]
            },
            gxklsf: {
                drawingHistory: [getWord('number_of_period'), getWord('first_ball'), getWord('second_ball'), getWord('third_ball'), getWord('fourth_ball'), getWord('fifth_ball')],
                currentRuleSize: 11,
                luzhu: [
                    { name: getWord('first_ball'), states: 1, num: 'num_one' },
                    { name: getWord('second_ball'), states: 2, num: 'num_two' },
                    { name: getWord('third_ball'), states: 3, num: 'num_three' },
                    { name: getWord('fourth_ball'), states: 4, num: 'num_four' },
                    { name: getWord('fifth_ball'), states: 5, num: 'num_five' },
                ],
            },
            fiveselect: {
                drawingHistory: [getWord('number_of_period'), getWord('first_ball'), getWord('second_ball'), getWord('third_ball'), getWord('fourth_ball'), getWord('fifth_ball')],
                currentRuleSize: 6,
                luzhu: [
                    { name: getWord('first_ball'), states: 1, num: 'num_one' },
                    { name: getWord('second_ball'), states: 2, num: 'num_two' },
                    { name: getWord('third_ball'), states: 3, num: 'num_three' },
                    { name: getWord('fourth_ball'), states: 4, num: 'num_four' },
                    { name: getWord('fifth_ball'), states: 5, num: 'num_five' },
                    { name: getWord('the_sum'), states: 6, num: 'num_zonghe' },
                    { name: getWord('1824'), states: 7, num: 'num_longhu' },
                    { name: getWord(['3035', 'small']), states: 8, num: 'num_wei' },
                ],
            }
        };
        return {
            drawingHistory: {
                title: {
                    lhc: titles.lhc.drawingHistory,
                    lhcaomen: titles.lhc.drawingHistory,
                    lhcone: titles.lhc.drawingHistory,
                    lhcthree: titles.lhc.drawingHistory,
                    lhcfive: titles.lhc.drawingHistory,
                    lhcten: titles.lhc.drawingHistory,

                    bjsc: titles.sc.drawingHistory,
                    xyft: titles.sc.drawingHistory,
                    azxy10: titles.sc.drawingHistory,
                    scone: titles.sc.drawingHistory,
                    scthree: titles.sc.drawingHistory,
                    scfive: titles.sc.drawingHistory,

                    hljssc: titles.ssc.drawingHistory,
                    tjssc: titles.ssc.drawingHistory,
                    cqssc: titles.ssc.drawingHistory,
                    sscone: titles.ssc.drawingHistory,
                    sscthree: titles.ssc.drawingHistory,
                    sscfive: titles.ssc.drawingHistory,
                    jndxy: titles.ssc.drawingHistory,
                    azxy5: titles.ssc.drawingHistory,

                    tencent: titles.tencent.drawingHistory,

                    gdfiveselect: titles.fiveselect.drawingHistory,
                    sdfiveselect: titles.fiveselect.drawingHistory,
                    jsfiveselect: titles.fiveselect.drawingHistory,
                    jxfiveselect: titles.fiveselect.drawingHistory,
                    shhfiveselect: titles.fiveselect.drawingHistory,
                    anhfiveselect: titles.fiveselect.drawingHistory,
                    shxfiveselect: titles.fiveselect.drawingHistory,

                    fc3d: titles.fc3d.drawingHistory,

                    cqklten: titles.gdklten.drawingHistory,
                    gdklten: titles.gdklten.drawingHistory,
                    gxklsf: titles.gxklsf.drawingHistory,

                    bj28: titles.pc28one.drawingHistory,
                    jnd28: titles.pc28one.drawingHistory,
                    twbg28: titles.pc28one.drawingHistory,
                    mgelg28: titles.pc28one.drawingHistory,
                    jndx28: titles.pc28one.drawingHistory,
                    snfk28: titles.pc28one.drawingHistory,
                    pc28one: titles.pc28one.drawingHistory,

                    fastthreeone: titles.fastthree.drawingHistory,
                    fastthreethree: titles.fastthree.drawingHistory,
                    fastthreefive: titles.fastthree.drawingHistory,
                    fastthreeten: titles.fastthree.drawingHistory,
                    shfastthree: titles.fastthree.drawingHistory,
                    jsfastthree: titles.fastthree.drawingHistory,
                    hbfastthree: titles.fastthree.drawingHistory,

                    tiantiancai: titles.ttc.drawingHistory,
                }
            },
            luzhu: {
                title: {
                    lhc: titles.lhc.luzhu,
                    lhcaomen: titles.lhc.luzhu,
                    lhcone: titles.lhc.luzhu,
                    lhcthree: titles.lhc.luzhu,
                    lhcfive: titles.lhc.luzhu,
                    lhcten: titles.lhc.luzhu,

                    bjsc: titles.sc.luzhu,
                    xyft: titles.sc.luzhu,
                    azxy10: titles.sc.luzhu,
                    scone: titles.sc.luzhu,
                    scthree: titles.sc.luzhu,
                    scfive: titles.sc.luzhu,

                    tencent: titles.tencent.luzhu,

                    hljssc: titles.ssc.luzhu,
                    tjssc: titles.ssc.luzhu,
                    cqssc: titles.ssc.luzhu,
                    sscone: titles.ssc.luzhu,
                    sscthree: titles.ssc.luzhu,
                    sscfive: titles.ssc.luzhu,
                    jndxy: titles.ssc.luzhu,
                    azxy5: titles.ssc.luzhu,

                    gdfiveselect: titles.fiveselect.luzhu,
                    sdfiveselect: titles.fiveselect.luzhu,
                    jsfiveselect: titles.fiveselect.luzhu,
                    jxfiveselect: titles.fiveselect.luzhu,
                    shhfiveselect: titles.fiveselect.luzhu,
                    anhfiveselect: titles.fiveselect.luzhu,
                    shxfiveselect: titles.fiveselect.luzhu,

                    fc3d: titles.fc3d.luzhu,

                    cqklten: titles.gdklten.luzhu,
                    gdklten: titles.gdklten.luzhu,
                    gxklsf: titles.gxklsf.luzhu,

                    bj28: titles.pc28one.luzhu,
                    jnd28: titles.pc28one.luzhu,
                    twbg28: titles.pc28one.luzhu,
                    mgelg28: titles.pc28one.luzhu,
                    jndx28: titles.pc28one.luzhu,
                    snfk28: titles.pc28one.luzhu,
                    pc28one: titles.pc28one.luzhu,

                    fastthreeone: titles.fastthree.luzhu,
                    fastthreethree: titles.fastthree.luzhu,
                    fastthreefive: titles.fastthree.luzhu,
                    fastthreeten: titles.fastthree.luzhu,
                    shfastthree: titles.fastthree.luzhu,
                    jsfastthree: titles.fastthree.luzhu,
                    hbfastthree: titles.fastthree.luzhu,

                    tiantiancai: titles.ttc.luzhu,
                }
            },
            ruleSize: {
                title: {
                    lhc: titles.lhc.currentRuleSize,
                    lhcaomen: titles.lhc.currentRuleSize,
                    lhcone: titles.lhc.currentRuleSize,
                    lhcthree: titles.lhc.currentRuleSize,
                    lhcfive: titles.lhc.currentRuleSize,
                    lhcten: titles.lhc.currentRuleSize,

                    bjsc: titles.sc.currentRuleSize,
                    xyft: titles.sc.currentRuleSize,
                    azxy10: titles.sc.currentRuleSize,
                    scone: titles.sc.currentRuleSize,
                    scthree: titles.sc.currentRuleSize,
                    scfive: titles.sc.currentRuleSize,

                    tencent: titles.tencent.currentRuleSize,

                    hljssc: titles.ssc.currentRuleSize,
                    tjssc: titles.ssc.currentRuleSize,
                    cqssc: titles.ssc.currentRuleSize,
                    sscone: titles.ssc.currentRuleSize,
                    sscthree: titles.ssc.currentRuleSize,
                    sscfive: titles.ssc.currentRuleSize,
                    jndxy: titles.ssc.currentRuleSize,
                    azxy5: titles.ssc.currentRuleSize,

                    gdfiveselect: titles.fiveselect.currentRuleSize,
                    sdfiveselect: titles.fiveselect.currentRuleSize,
                    jsfiveselect: titles.fiveselect.currentRuleSize,
                    jxfiveselect: titles.fiveselect.currentRuleSize,
                    shhfiveselect: titles.fiveselect.currentRuleSize,
                    anhfiveselect: titles.fiveselect.currentRuleSize,
                    shxfiveselect: titles.fiveselect.currentRuleSize,

                    fc3d: titles.fc3d.currentRuleSize,

                    cqklten: titles.gdklten.currentRuleSize,
                    gdklten: titles.gdklten.currentRuleSize,
                    gxklsf: titles.gxklsf.currentRuleSize,

                    bj28: titles.pc28one.currentRuleSize,
                    jnd28: titles.pc28one.currentRuleSize,
                    twbg28: titles.pc28one.currentRuleSize,
                    mgelg28: titles.pc28one.currentRuleSize,
                    jndx28: titles.pc28one.currentRuleSize,
                    snfk28: titles.pc28one.currentRuleSize,
                    pc28one: titles.pc28one.currentRuleSize,

                    fastthreeone: titles.fastthree.currentRuleSize,
                    fastthreethree: titles.fastthree.currentRuleSize,
                    fastthreefive: titles.fastthree.currentRuleSize,
                    fastthreeten: titles.fastthree.currentRuleSize,
                    shfastthree: titles.fastthree.currentRuleSize,
                    jsfastthree: titles.fastthree.currentRuleSize,
                    hbfastthree: titles.fastthree.currentRuleSize,

                    tiantiancai: titles.ttc.currentRuleSize,
                }
            },

            list: [], //长龙变量
            cloudBaseUrl,
            gameType: JSON.parse(localStorage.getItem('game')).type,
            topTitle: 1, //头部表格名称
            buttonTitle: 1, //底部露珠导航
            leftTitle: 0, //左侧大小单双导航,0为单双，1为大小
            issuseList: [],
            issuseTopTableTitCqssc: [getWord('number_of_period'), '1', '2', '3', '4', '5'], //头部历史表格标题--cqssc
            issuseTopTableTitTwo8: [getWord('number_of_period'), '1', '2', '3', getWord('te')], //头部历史表格标题--28
            issuseTopTableTitPk10: [getWord('number_of_period'), '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'], //头部历史表格标题--pk10
            issuseTopNavi: [{
                name: getWord('number_of_item'),
                states: 1
            },
            {
                name: getWord(['dan', 'shuang']),
                states: 2
            },
            {
                name: getWord(['da', 'small']),
                states: 3
            }
            ], //头部历史开奖导航
            lzBottomTitleNavi: { //头部历史开奖导航
                cqssc: [
                    {
                        name: getWord('first_ball'),
                        states: 1,
                        num: 'num_one'
                    },
                    {
                        name: getWord('second_ball'),
                        states: 2,
                        num: 'num_two'
                    },
                    {
                        name: getWord('third_ball'),
                        states: 3,
                        num: 'num_three'
                    },
                    {
                        name: getWord('fourth_ball'),
                        states: 4,
                        num: 'num_four'
                    },
                    {
                        name: getWord('fifth_ball'),
                        states: 5,
                        num: 'num_five'
                    }
                ],
                pk10: [
                    {
                        name: getWord('first4'),
                        states: 1,
                        num: 'num_one'
                    },
                    {
                        name: getWord('second4'),
                        states: 2,
                        num: 'num_two'
                    },
                    {
                        name: getWord('third4'),
                        states: 3,
                        num: 'num_three'
                    },
                    {
                        name: getWord('fourth_place'),
                        states: 4,
                        num: 'num_four'
                    },
                    {
                        name: getWord('fifth_place'),
                        states: 5,
                        num: 'num_five'
                    },
                    {
                        name: getWord('sixth_place'),
                        states: 6,
                        num: 'num_six'
                    },
                    {
                        name: getWord('seventh_place'),
                        states: 7,
                        num: 'num_seven'
                    },
                    {
                        name: getWord('eight_place'),
                        states: 8,
                        num: 'num_eight'
                    },
                    {
                        name: getWord('ninth_place'),
                        states: 9,
                        num: 'num_nine'
                    },
                    {
                        name: getWord('tenth_place'),
                        states: 10,
                        num: 'num_ten'
                    }
                ],
                pc28: [
                    {
                        name: getWord('first_ball'),
                        states: 1,
                        num: 'num_one'
                    },
                    {
                        name: getWord('second_ball'),
                        states: 2,
                        num: 'num_two'
                    },
                    {
                        name: getWord('third_ball'),
                        states: 3,
                        num: 'num_three'
                    },
                    {
                        name: getWord('item_tema'),
                        states: 4,
                        num: 'num_tema'
                    }
                ]
            },
            //露珠模块用的变量
            lzList: [],
            main_road: [],
            main_DX: [],
            red_D: 0, //大的开奖总数
            blue_X: 0, //小的开奖总数
            blue_double: 0, //双的开奖总数
            red_single: 0, //单的开奖总数
            dewdrop_chache: [], //单双
            dewdrop_chache_length: '',
            lower_width: {
                width: '5.1rem',
            },
            lower_width1: {
                width: '5.1rem',
            },
            maxResultLength: 50,
            currentRule: 'odd',
            currentRuleSize: null,
            currentRuleTema: false,
            currentNumberId: '',
            currentRuleResult: '',
            currentRuleCount: {
                odd: 0,
                size: 0,
                draw: 0,
            },
            continuousPool: [],

            date: null,
            type: localStorage.getItem('game') && JSON.parse(localStorage.getItem('game')).type,
            loading_data: true,
            page: 1,
            pageSize: 20,

            tencent: {
                longhuhe: [],
            },

            header: {
                mainTitle: getWord('trend5'),
                mainTitle2: getWord('luzhu') + '/' + getWord('changlong')
            },

            /* template-3 begin */
            issuseList2: [],
            currentLuzhuTabTitle: '',
            changlongData: [],
        }
    },
    methods: {
        checkLanguage(index, item) {

            // if ( window.language!=='cn' && index===0 ) {
            //     return true
            // }

            if (window.language !== 'cn' && index > 0 && isNaN(item)) {
                return true
            }

        },
        loadMore_data() {
            if (this.page >= this.totalPage) {
                return false;
            } else {
                this.page++;
                this.history_lottery(false);
            }
        },
        issuserTopNaviCheck(item) {
            this.topTitle = item;
        },
        lzNaviCheck(it) {
            if (it.num === 'num_longhu' || it.num === 'num_wei') { this.currentRule = 'odd' }
            this.buttonTitle = it.states;
            this.currentLuzhuTabTitle = it.num
            this.lzBottomDX(
                this.lzList,
                this.luzhu.title[this.gameType][this.buttonTitle - 1].num,
                this.currentRule === 'size' ? true : false,
            )
        },
        lzConLeftnavi(it) {
            if (this.currentLuzhuTabTitle === 'num_longhu' || this.currentLuzhuTabTitle === 'num_wei') return false;
            this.currentRule = it === 0 ? 'odd' : 'size'
            this.leftTitle = it;
            this.lzBottomDX(
                this.lzList,
                this.luzhu.title[this.gameType][this.buttonTitle - 1].num,
            )
        },
        lzBottomDX(_result, num, sizeTag, initTag) {

            this.currentRuleSize = this.ruleSize.title[this.gameType];

            if (this.gameType.indexOf('fiveselect') > -1) {
                if (num === 'num_zonghe') {
                    this.currentRuleSize = 30;
                } else {
                    this.currentRuleSize = this.ruleSize.title[this.gameType];
                }
            }

            if (this.gameType.indexOf('28') > -1) {
                if (num === 'num_tema') {
                    this.currentRuleSize = 14;
                } else {
                    this.currentRuleSize = this.ruleSize.title[this.gameType];
                }
            }

            var _result = _result.slice(0);
            var that = this;
            var lastValue = null;
            var latestValue = null;
            var lastValueOdds = null;
            var latestValueOdds = null;
            var newLineTag = false;
            var maxLength = 6;
            var result = [];
            that.currentRuleCount.draw = 0;

            if (that.currentRule === 'odd' || initTag) {
                that.currentRuleCount.odd = 0;
            }
            if (that.currentRule === 'size' || initTag) {
                that.currentRuleCount.size = 0;
            }

            this.continuousPool[num] = [];


            function checkNull(item) {
                return item.filter(item => {
                    return item === null
                }).length
            }
            function calcRule(value, rule) {
                if (sizeTag === true) {
                    that.currentRule = 'size'
                }
                if (rule === 'odd') {
                    return value % 2 !== 0; //单=true
                } else {
                    return value >= that.currentRuleSize //大=true
                }
            }

            function calcResult(rule) {
                var counter = 0;
                // console.log(_result)
                _result.forEach((item, index) => {
                    var drawNumber = that.type == 'lhc'
                        ? 49
                        : that.type.indexOf('fiveselect') > -1
                            ? 30
                            : -1
                    latestValue = Number(item[num]);
                    if (latestValue === drawNumber) {
                        latestValueOdds = 'draw'
                        that.currentRuleCount.draw++;
                    } else {
                        latestValueOdds = calcRule(latestValue, rule) ? true : false;
                    }

                    if (latestValueOdds) {
                        if (rule === 'odd') {
                            that.currentRuleCount.odd++;
                        } else {
                            that.currentRuleCount.size++;
                        }
                    } else {

                    }

                    //new data line from api result
                    if (lastValue === null) {
                        lastValue = latestValue
                        lastValueOdds = latestValueOdds;
                        result.push([])
                    }
                    // console.log(that.currentRule,latestValue)
                    if (latestValueOdds !== lastValueOdds) {

                        if (index > 0) {
                            // console.log('>0',num,rule,lastValueOdds, counter)
                            that.continuousPool[num].push({
                                'type': rule + '|' + lastValueOdds,
                                'count': counter
                            })
                        }
                        counter = 1;

                        if (index === _result.length - 1) {
                            // console.log('!>0',num,rule,latestValueOdds, counter)
                            that.continuousPool[num].push({
                                'type': rule + '|' + latestValueOdds,
                                'count': counter
                            })
                        }

                        lastValue = latestValue;
                        lastValueOdds = latestValueOdds;
                        result.push([])
                        result[result.length - 1].push(lastValueOdds)
                    } else {
                        // console.log(latestValueOdds)

                        counter++
                        if (index === _result.length - 1) {
                            // console.log('==0',num,rule,latestValueOdds, counter)
                            that.continuousPool[num].push({
                                'type': rule + '|' + latestValueOdds,
                                'count': counter
                            })
                        }
                        if (result[result.length - 1].length === maxLength && result[result.length - 1][4] === null && newLineTag === false) {
                            var nullLength = checkNull(result[result.length - 1])
                            result[result.length - 1].splice(maxLength - 1 - nullLength, 1, lastValueOdds)
                        } else if (result[result.length - 1].length === maxLength) {
                            result.push(Array(maxLength));
                            result[result.length - 1].fill(null);
                            result[result.length - 1].splice(maxLength - 1, 1, lastValueOdds)
                            newLineTag = true;
                        } else {
                            result[result.length - 1].push(lastValueOdds)
                        }
                    }
                    // if (latestValueOdds!==lastValueOdds) {
                    //     counter=0;
                    // }else {
                    //     counter++
                    // }

                    // console.log(lastValue,lastValueOdds)

                })
            }

            function checkNullIndex() {
                var nullIndex = [[]]
                result.forEach((item, index) => {
                    if (item[0] === null) {
                        if (parseInt(nullIndex[nullIndex.length - 1][nullIndex[nullIndex.length - 1].length - 1]) + 1 !== index && nullIndex[nullIndex.length - 1][0] !== undefined) {
                            nullIndex.push([])
                        }
                        nullIndex[nullIndex.length - 1].push(index)
                    }
                })
                if (nullIndex[0][nullIndex[0].length - 1] === result.length - 1) {
                    return false;
                } else {
                    return nullIndex[0]
                }
            }

            calcResult(that.currentRule);
            // console.log(that.continuousPool)
            // var _odd=[];
            // that.continuousPool.forEach(item=>{
            //     if (item.odd) {
            //         _odd.push(item)
            //     }
            // })

            // console.log(_odd.sort(function(a,b){                return b.count-a.count            }))

            if (checkNullIndex().length > 0) {
                mergeResult(checkNullIndex())
            }

            function mergeResult(nullIndex) {
                var head = result.slice(0, nullIndex[0])
                var body = result.slice(nullIndex[0], nullIndex[nullIndex.length - 1] + 1)
                var foot = result.slice(nullIndex[nullIndex.length - 1] + 1)
                var rest = merge(body, foot);
                result = head.concat(rest)

                if (checkNullIndex().length > 0) {
                    mergeResult(checkNullIndex())
                }
            }

            this.currentRuleResult = result.slice(0);

            // result=[];
            // lastValue=null;
            // latestValue=null;
            // lastValueOdds=null;
            // latestValueOdds=null;
            // newLineTag=false;
            // calcResult('odd');
            // this.currentRuleCount.odd=result.reduce((acc, value) => acc.concat(value), []).filter(item=>{
            //     return item===true
            // }).length
            // result=[];
            // lastValue=null;
            // latestValue=null;
            // lastValueOdds=null;
            // latestValueOdds=null;
            // newLineTag=false;
            if (initTag) {
                calcResult(that.currentRule === 'odd' ? 'size' : 'odd');
            }
            // this.currentRuleCount.size=result.reduce((acc, value) => acc.concat(value), []).filter(item=>{
            //     return item===true
            // }).length

            function getIndexOfK(arr, k) {
                for (var i = 0; i < arr.length; i++) {
                    var index = arr[i].indexOf(k);
                    if (index > -1) {
                        return i;
                    }
                }
            }
            function merge(a, b) {
                const merged = [];
                for (let i = 0; i < a.length || i < b.length; i++) {
                    merged.push(a[i] && b[i] ? Object.values({ ...a[i], ...b[i] }) : a[i] || b[i]);
                }
                return merged;
            }
            var firstNewLineIndex = getIndexOfK(result, null);

            this.$nextTick(function () {
                if (this.$refs.trendsLuzhu) {
                    this.$refs.trendsLuzhu.scrollLeft = 1000;
                }
            })
        },


        isDXDS(data, tema) {
            let c = { num: data, dx: '', ds: '' };
            var breakpoint = tema ? true : false
            if (this.gameType == 'cqssc') {
                if (data >= 5) {
                    c.dx = getWord('da');
                } else {
                    c.dx = getWord('small');
                }
                if (data % 2 == 0) {
                    c.ds = getWord('shuang');
                } else {
                    c.ds = getWord('dan');
                }
            } else {
                if (data >= (breakpoint ? 14 : 6)) {
                    c.dx = getWord('da');
                } else {
                    c.dx = getWord('small');
                }
                if (data % 2 == 0) {
                    c.ds = getWord('shuang');
                } else {
                    c.ds = getWord('dan');
                }
            }
            return c;
        },

        TEMPLATE3_switchType(type) {
            var _type = type === 'odd' ? 0 : 1;
            this.currentRule = type;
            this.buttonTitle = 4;
            this.lzConLeftnavi(_type);

        },

        history_lottery(type) {
            //两天的数据
            let startTime = parseInt((new Date().getTime() - 3600 * 24 * 1000) / 1000);
            let endTime = parseInt((new Date().getTime()) / 1000);
            //六合彩时，一个月数据
            if (type) {
                startTime = parseInt(new Date().setMonth(new Date().getMonth() - 1) / 1000);
                endTime = parseInt((new Date().getTime()) / 1000);
            }
            lottery_record(
                this.type,
                this.page,
                this.pageSize,
                startTime,
                endTime
            ).then(result => {
                if (result.data.code == "SUCCESS") {
                    // this.list=result.data.result;
                    for (var i = 0; i <= result.data.result.list.length - 1; i++) {
                        this.lzList.push(result.data.result.list[i]);
                    }
                    this.totalPage = result.data.result.totalPage;
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            });
            this.loading_data = false;
        },
        switchTab(item) {
            var activated = this.tencent.longhuhe.filter(item => {
                return item.active === true
            })
            if (activated.length > 0) {
                activated.forEach(item => {
                    item.active = false;
                })
            }
            item.active = true;
        },
        calcChanglong(data) {
            Object.keys(data).forEach(number => {
                data[number].forEach(item => {
                    this.changlongData.push({
                        position: number,
                        type: item.type,
                        value: item.count,
                    })
                })
            })
            this.changlongData.sort((a, b) => {
                return b.value - a.value
            })
        },
        changlongRender(data, genre) {
            var related = null;
            var _nameLeft = null;
            var _nameRight = null;
            if (genre === 'position') {
                related = this.luzhu.title[this.gameType].filter(item => {
                    return item.num === data
                })
                if (related.length > 0) {
                    return related[0].name
                }
            }
            if (genre === 'type') {
                var _nameLeft = data.split('|')[0];
                var _nameRight = data.split('|')[1];
                if (_nameLeft === 'odd') {
                    if (_nameRight === 'true') {
                        return getWord('dan')
                    } else {
                        return getWord('shuang')
                    }
                }
                if (_nameLeft === 'size') {
                    if (_nameRight === 'true') {
                        return getWord('da')
                    } else {
                        return getWord('small')
                    }
                }
            }
        }
    },
    mounted() {
        var that = this;
        this.CustomScroll(this.$refs.trendsByLuzhu.id);

        var start = null;
        if (this.gameType === 'lhc' || this.gameType === 'fc3d' || this.gameType === 'tiantiancai') {
            start = Math.round((new Date().getTime() - 60 * 60 * 24 * 30 * 1000) / 1000);
        } else if (this.gameType.indexOf('one') > -1 || this.gameType.indexOf('tencent') > -1) {
            start = Math.round((new Date().getTime() - 60 * 60 * 2 * 1000) / 1000);
        } else if (this.gameType.indexOf('scthree') > -1 || this.gameType.indexOf('sscthree') > -1 || this.gameType.indexOf('lhcthree') > -1) {
            start = Math.round((new Date().getTime() - 60 * 60 * 6 * 1000) / 1000);
        } else {
            start = Math.round((new Date().getTime() - 60 * 60 * 24 * 1000) / 1000);
        }
        var currentTimeStamp = Math.round(new Date().getTime() / 1000);

        lottery_record(
            this.gameType,
            null,
            null,
            start,
            currentTimeStamp,
        ).then(_result => {
            if (_result.data.code === 'SUCCESS') {

                var mappingKey = ['num_one', 'num_two', 'num_three', 'num_four', 'num_five']
                var n = mappingKey.length;
                var series = [];
                for (var i = 1; i < n; i++) {
                    if (n - i > 0) {
                        for (var j = i + 1; j < n + 1; j++) {
                            series.push(i + ' ' + j)
                        }
                    }
                }
                function getValue(a, b) {
                    var result = [];
                    _result.data.result.forEach(item => {
                        item[mappingKey[a - 1]] - item[mappingKey[b - 1]] === 0
                            ? result.push(0)
                            : item[mappingKey[a - 1]] - item[mappingKey[b - 1]] > 0
                                ? result.push(1)
                                : result.push(-1)
                    })
                    return result;
                }
                if (this.gameType === 'tencent') {
                    series.forEach(item => {
                        this.tencent.longhuhe.push({
                            title: item.split(' ')[0] + 'VS' + item.split(' ')[1],
                            active: false,
                            value: getValue(item.split(' ')[0], item.split(' ')[1]),
                        })
                    })
                    this.tencent.longhuhe[0].active = true;
                }

                let lengthNum = 0;

                if (this.gameType.indexOf('28') > -1) {
                    for (let tema = 0; tema < _result.data.result.length; tema++) {
                        let num_tema = parseInt(_result.data.result[tema].num_one) + parseInt(_result.data.result[tema].num_two) + parseInt(_result.data.result[tema].num_three);
                        _result.data.result[tema].num_tema = num_tema;
                    }
                }
                that.maxResultLength = _result.data.result.length > that.maxResultLength ? that.maxResultLength : _result.data.result.length
                _result.data.result.length = that.maxResultLength;

                if (this.gameType.indexOf('28') > -1 || this.gameType === 'cqssc' || this.gameType === 'bjsc' || this.gameType === 'xyft') {
                    this.lzList = _result.data.result;
                    if (_result.data.result.length >= 50) {
                        lengthNum = 50;
                    } else {
                        lengthNum = _result.data.result.length;
                    }
                    for (let i = 0; i < lengthNum; i++) {
                        let a = { issue: '', numbers: [] };
                        a.issue = _result.data.result[i].issue;
                        if (this.gameType.indexOf('28') > -1) {
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_one));
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_two));
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_three));
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_tema, true));
                        } else if (this.gameType == 'cqssc') {
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_one));
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_two));
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_three));
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_four));
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_five));
                        } else if (this.gameType == 'xyft' || this.gameType == 'bjsc') {
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_one));
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_two));
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_three));
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_four));
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_five));
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_six));
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_seven));
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_eight));
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_nine));
                            a.numbers.push(this.isDXDS(_result.data.result[i].num_ten));
                        }
                        this.issuseList.push(a);
                    }
                    var _arr = []
                    this.issuseList.forEach((item, index) => {
                        if (index % 5 === 0) {
                            _arr.push([])
                            _arr[_arr.length - 1].push(item)
                        } else {
                            _arr[_arr.length - 1].push(item)
                        }
                    })
                    this.issuseList2 = _arr;
                } else {
                    this.lzList = _result.data.result;

                    if (_result.data.result.length >= 6) {
                        lengthNum = 6;
                    } else {
                        lengthNum = _result.data.result.length;
                    }
                    for (var i = 0; i < lengthNum; i++) {
                        var a = {
                            issue: '',
                            numbers: []
                        };
                        a.issue = _result.data.result[i].issue;

                        if (this.gameType.indexOf('fc3d') > -1) {
                            a.numbers.push(_result.data.result[i].num_one);
                            a.numbers.push(_result.data.result[i].num_two);
                            a.numbers.push(_result.data.result[i].num_three);
                        }
                        if (that.gameType.indexOf('pc28one') > -1) {
                            a.numbers.push(_result.data.result[i].num_one);
                            a.numbers.push(_result.data.result[i].num_two);
                            a.numbers.push(_result.data.result[i].num_three);
                            a.numbers.push(_result.data.result[i].sum);
                        }
                        if (this.gameType.indexOf('fc3d') === -1 && this.gameType.indexOf('pc28one') === -1) {
                            a.numbers.push(_result.data.result[i].num_one);
                            a.numbers.push(_result.data.result[i].num_two);
                            a.numbers.push(_result.data.result[i].num_three);
                            a.numbers.push(_result.data.result[i].num_four);
                            a.numbers.push(_result.data.result[i].num_five);
                            if (this.gameType == 'lhc' || this.gameType == 'lhcaomen' || this.gameType == 'lhcone' || this.gameType == 'lhcthree' || this.gameType == 'lhcfive' || this.gameType == 'lhcten') {
                                a.numbers.push(_result.data.result[i].num_six);
                                a.numbers.push(_result.data.result[i].num_tema);
                            } else if (this.gameType === 'gdklten' || this.gameType === 'tiantiancai') {
                                a.numbers.push(_result.data.result[i].num_six);
                                a.numbers.push(_result.data.result[i].num_seven);
                                a.numbers.push(_result.data.result[i].num_eight);
                                if (this.gameType === 'tiantiancai') {
                                    a.numbers.push(_result.data.result[i].num_tema1);
                                    a.numbers.push(_result.data.result[i].num_tema2);
                                }
                            } else if (this.gameType.indexOf('ssc') == -1 && this.gameType.indexOf('tencent') === -1 && this.gameType.indexOf('fiveselect') === -1 && this.gameType.indexOf('gxklsf') === -1) {
                                a.numbers.push(_result.data.result[i].num_six);
                                a.numbers.push(_result.data.result[i].num_seven);
                                a.numbers.push(_result.data.result[i].num_eight);
                                a.numbers.push(_result.data.result[i].num_nine);
                                a.numbers.push(_result.data.result[i].num_ten);
                            }
                        }
                        this.issuseList.push(a);
                    }
                }

                this.lzList.forEach(item => {
                    var total = 0; mappingKey.forEach(key => { total += item[key] }); item.num_zonghe = total
                })//num_zonghe

                this.lzList.forEach(item => {
                    item.num_longhu = item[mappingKey[0]] - item[mappingKey[mappingKey.length - 1]] > 0
                        ? true
                        : false
                })//num_longhu

                this.lzList.forEach(item => {
                    item.num_wei = parseInt(item.num_zonghe.toString().split('')[1]) >= 5
                        ? true
                        : false
                })//num_wei

                that.luzhu.title[that.gameType].forEach((item, index) => {
                    that.lzBottomDX(
                        that.lzList,
                        that.luzhu.title[that.gameType][index].num,
                        false,
                        true,
                    )
                })

                that.lzConLeftnavi(0);

                this.calcChanglong(that.continuousPool)

                if (this.$refs.trendsLuzhu) {
                    this.CustomScroll(this.$refs.trendsLuzhu.id);
                }
            } else {
                app.Mint.Toast(_result.data.msg);
            }
        })
    }
};
</script>
<template>
    <div :id="currentType === 'odd' ? 'trends-by-luzhu-odd' : currentType === 'size' ? 'trends-by-luzhu-size' : 'trends-by-luzhu'"
        ref='trendsByLuzhu' :class="_TEMPLATE">
        <template v-if="currentType !== 'odd' && currentType !== 'size'">
            <template v-if="_TEMPLATE === 'template-1' || _TEMPLATE === 'template-2'">
                <template v-if="currentType !== 'long'">
                    <!-- <Header :mainTitle="gameType.indexOf('28') > -1 ? header.mainTitle2 : header.mainTitle" /> -->

                    <h3>{{ getWord(['luzhu', 'trends']) }}</h3>
                    <div class="luzhu-zhupan">
                        <div>
                            <table border="1" cellpadding="0" cellspacing="0">
                                <tr>
                                    <th v-for="(item, index) in drawingHistory.title[gameType]">
                                        <span :class="{ 'non-chinese': checkLanguage(index, item) }">{{ item }}</span>
                                    </th>
                                </tr>
                                <tr v-for="item in issuseList.slice(0, 6)">
                                    <template v-if="_TEMPLATE !== 'template-2'">
                                        <td>{{ item.issue }}</td>
                                    </template>
                                    <template v-else>
                                        <td>{{ item.issue.substr(-8) }}</td>
                                    </template>
                                    <td v-for="item in item.numbers">{{ item instanceof Object ? item.num : item }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="luzhu-da">
                        <div class="lzBottomTitle">
                            <span @click="lzNaviCheck(item)" v-for="item in luzhu.title[gameType]"
                                :class="{ 'check': item.states == buttonTitle }">{{ item.name }}</span>
                        </div>

                        <div class="lzBottomcon">
                            <div class="left">
                                <template v-for="index in 2">
                                    <span @click="lzConLeftnavi(index - 1)" :class="{ 'check': leftTitle == index - 1 }">
                                        <template v-if="currentLuzhuTabTitle === 'num_longhu'">
                                            <p v-if="index - 1 === 0" class="first"><i>{{ getWord('long')
                                            }}</i>&nbsp;&nbsp;{{ currentRuleCount.odd }}</p>
                                            <p v-else class="second"><i>{{ getWord('hu')
                                            }}</i>&nbsp;&nbsp;{{ maxResultLength - currentRuleCount.odd - (currentRuleCount.draw / 3) }}
                                            </p>
                                        </template>
                                        <template v-else-if="currentLuzhuTabTitle === 'num_wei'">
                                            <p v-if="index - 1 === 0" class="first"><i>{{ getWord('da')
                                            }}</i>&nbsp;&nbsp;{{ currentRuleCount.odd }}</p>
                                            <p v-else class="second"><i>{{ getWord('small')
                                            }}</i>&nbsp;&nbsp;{{ maxResultLength - currentRuleCount.odd - (currentRuleCount.draw / 3) }}
                                            </p>
                                        </template>
                                        <template v-else>
                                            <template v-if="index - 1 === 0">
                                                <p><i>{{ getWord('dan') }}</i>&nbsp;&nbsp;{{ currentRuleCount.odd }}</p>
                                                <p><i>{{ getWord('shuang')
                                                }}</i>&nbsp;&nbsp;{{ maxResultLength - currentRuleCount.odd - (currentRuleCount.draw) }}
                                                </p>
                                            </template>
                                            <template v-else>
                                                <p><i>{{ getWord('da') }}</i>&nbsp;&nbsp;{{ currentRuleCount.size }}</p>
                                                <p><i>{{ getWord('small')
                                                }}</i>&nbsp;&nbsp;{{ maxResultLength - currentRuleCount.size - (currentRuleCount.draw) }}
                                                </p>
                                            </template>
                                        </template>
                                    </span>
                                </template>
                            </div>
                            <div class="right" id="trends-luzhu" ref='trendsLuzhu'>
                                <table border="0" cellpadding="0" cellspacing="0">
                                    <tr v-for="(items, indexs) in currentRuleResult" :key="indexs">
                                        <template v-for="(item, index) in 6">
                                            <td v-if="items[index] === true" :key="index">
                                                <p class="red">
                                                    {{ currentLuzhuTabTitle === 'num_longhu' ? getWord('long') : (currentLuzhuTabTitle === 'num_wei'
                                                            || leftTitle == 1) ? getWord('da') : getWord('dan')
                                                    }}</p>
                                            </td>
                                            <td v-else-if="items[index] === false">
                                                <p class="blue">
                                                    {{ currentLuzhuTabTitle === 'num_longhu' ? getWord('hu') : (currentLuzhuTabTitle === 'num_wei'
                                                            || leftTitle == 1) ? getWord('small') : getWord('shuang')
                                                    }}</p>
                                            </td>
                                            <td v-else-if="items[index] === 'draw'">
                                                <p class="gray">{{ getWord('draw') }}</p>
                                            </td>
                                            <td v-else></td>
                                        </template>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="longline" v-if="changlongData && changlongData.length">
                    <h3>{{ getWord('changlong3') }}</h3>
                    <ul>
                        <li v-for="(item, index) in changlongData.filter(item => { return item.value > 2 })" :key="index">
                            <span>{{ changlongRender(item.position, 'position') + '-' + changlongRender(item.type, 'type')
                            }}</span>
                            <span>{{ item.value + getWord('period') }}</span>
                        </li>
                    </ul>
                </div>
            </template>
            <template v-if="_TEMPLATE === 'template-3'">
                <Header :mainTitle="gameType.indexOf('28') > -1 ? header.mainTitle2 : header.mainTitle" />

                <template v-if="gameType.indexOf('fastthree') > -1">
                    <table class="luzhu-list kuai3" cellspacing="0" border="0">
                        <tr>
                            <th>{{ getWord('drawing_number') }}</th>
                            <th>{{ getWord('drawing_result2') }}</th>
                            <th>{{ getWord('hezhi') }}</th>
                            <th>{{ getWord('size') }}</th>
                            <th>{{ getWord('single_and_double') }}</th>
                        </tr>
                        <tr v-for="item in lzList">
                            <td>{{ item.issue }}</td>
                            <td>
                                <span class="dice" :class="'dice-' + item.num_one"></span>
                                <span class="dice" :class="'dice-' + item.num_two"></span>
                                <span class="dice" :class="'dice-' + item.num_three"></span>
                            </td>
                            <td>{{ item.num_one + item.num_two + item.num_three + getWord('dot') }}</td>
                            <td>{{ (item.num_one + item.num_two + item.num_three) > 10 ? getWord('da') : getWord('small') }}</td>
                            <td>{{ (item.num_one + item.num_two + item.num_three) % 2 === 0 ? getWord('shuang') : getWord('dan') }}
                            </td>
                        </tr>
                    </table>
                </template>
                <template v-else>
                    <template v-if="gameType.indexOf('28') > -1">
                        <ul id="switcher">
                            <li @click="TEMPLATE3_switchType('odd')" :class="{ active: currentRule === 'odd' }">{{
                                    getWord('luzhu_odd')
                            }}</li>
                            <li @click="TEMPLATE3_switchType('size')" :class="{ active: currentRule === 'size' }">{{
                                    getWord('luzhu_size')
                            }}</li>
                        </ul>

                        <h3>
                            {{ getWord('dalu') }}
                            <i>（{{ getWord('luzhu_tips') }}）</i>
                            <div class="count">
                                <span>{{ currentRule === 'size' ? getWord('da') : getWord('dan') }}</span>
                                <i>{{ currentRule === 'size' ? currentRuleCount.size : currentRuleCount.odd }}</i>
                                <span>{{ currentRule === 'size' ? getWord('small') : getWord('shuang') }}</span>
                                <i>{{ currentRule === 'size' ? maxResultLength - currentRuleCount.size : maxResultLength - currentRuleCount.odd }}</i>
                            </div>
                        </h3>
                        <div class="luzhu-da">
                            <div class="lzBottomcon clearfix">
                                <div class="right" id="trends-luzhu" ref='trendsLuzhu'>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tr v-for="(items, indexs) in currentRuleResult" :key="indexs">
                                            <template v-for="(item, index) in 6">
                                                <td v-if="items[index] === true" :key="index">
                                                    <p class="red">
                                                        {{ currentLuzhuTabTitle === 'num_longhu' ? getWord('dragon') : (currentLuzhuTabTitle === 'num_wei'
                                                                || leftTitle == 1) ? getWord('da') : getWord('dan')
                                                        }}</p>
                                                </td>
                                                <td v-else-if="items[index] === false">
                                                    <p class="blue">
                                                        {{ currentLuzhuTabTitle === 'num_longhu' ? getWord('tiger') : (currentLuzhuTabTitle === 'num_wei'
                                                                || leftTitle == 1) ? getWord('small') : getWord('shuang')
                                                        }}</p>
                                                </td>
                                                <td v-else-if="items[index] === 'draw'">
                                                    <p class="gray">{{ getWord('draw') }}</p>
                                                </td>
                                                <td v-else></td>
                                            </template>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="luzhu-zhupan">
                            <ul
                                v-if="gameType.indexOf('28') > -1 || gameType === 'cqssc' || gameType === 'bjsc' || gameType === 'xyft'">
                                <li @click="issuserTopNaviCheck(item.states)" v-for="(item, index) in issuseTopNavi"
                                    :key="index" :class="{ 'highlight': item.states == topTitle }">{{ item.name }}</li>
                            </ul>

                            <div v-if="gameType == 'cqssc'">
                                <table v-if="topTitle == 1" border="1" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <th v-for="(item, index) in issuseTopTableTitCqssc" :key="index">{{ item }}</th>
                                    </tr>
                                    <tr v-for="(item, index) in issuseList.slice(0, 6)" :key="index">
                                        <td>{{ item.issue }}</td>
                                        <td v-for="(is, x) in item.numbers" :key="x">{{ is.num }}</td>
                                    </tr>
                                </table>
                                <table v-else-if="topTitle == 2" border="1" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <th v-for="(item, index) in issuseTopTableTitCqssc" :key="index">{{ item }}</th>
                                    </tr>
                                    <tr v-for="(item, index) in issuseList.slice(0, 6)" :key="index">
                                        <td>{{ item.issue }}</td>
                                        <td v-for="(is, x) in item.numbers" :key="x"
                                            :class="{ 'red': is.ds == getWord('dan'), 'blue': is.ds == getWord('shuang') }">
                                            {{ is.ds }}</td>
                                    </tr>
                                </table>
                                <table v-else-if="topTitle == 3" border="1" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <th v-for="(item, index) in issuseTopTableTitCqssc" :key="index">{{ item }}</th>
                                    </tr>
                                    <tr v-for="(item, index) in issuseList.slice(0, 6)" :key="index">
                                        <td>{{ item.issue }}</td>
                                        <td v-for="(is, x) in item.numbers" :key="x"
                                            :class="{ 'red': is.dx == getWord('da'), 'blue': is.dx == getWord('small') }">
                                            {{ is.dx }}</td>
                                    </tr>
                                </table>
                            </div>

                            <div v-else-if="gameType == 'bjsc' || gameType == 'xyft'">
                                <table v-if="topTitle == 1" border="1" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <th v-for="(item, index) in issuseTopTableTitPk10" :key="index">{{ item }}</th>
                                    </tr>
                                    <tr v-for="(item, index) in issuseList.slice(0, 6)" :key="index">
                                        <td>{{ item.issue }}</td>
                                        <td v-for="(is, x) in item.numbers" :key="x">{{ is.num }}</td>
                                    </tr>
                                </table>
                                <table v-else-if="topTitle == 2" border="1" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <th v-for="(item, index) in issuseTopTableTitPk10" :key="index">{{ item }}</th>
                                    </tr>
                                    <tr v-for="(item, index) in issuseList.slice(0, 6)" :key="index">
                                        <td>{{ item.issue }}</td>
                                        <td v-for="(is, x) in item.numbers" :key="x"
                                            :class="{ 'red': is.ds == getWord('dan'), 'blue': is.ds == getWord('shuang') }">
                                            {{ is.ds }}</td>
                                    </tr>
                                </table>
                                <table v-else-if="topTitle == 3" border="1" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <th v-for="(item, index) in issuseTopTableTitPk10" :key="index">{{ item }}</th>
                                    </tr>
                                    <tr v-for="(item, index) in issuseList.slice(0, 6)" :key="index">
                                        <td>{{ item.issue }}</td>
                                        <td v-for="(is, x) in item.numbers" :key="x"
                                            :class="{ 'red': is.dx == getWord('da'), 'blue': is.dx == getWord('small') }">
                                            {{ is.dx }}</td>
                                    </tr>
                                </table>
                            </div>

                            <div v-else-if="gameType.indexOf('28') > -1">
                                <table v-if="topTitle == 1" border="1" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <th v-for="(item, index) in issuseTopTableTitTwo8" :key="index">{{ item }}</th>
                                    </tr>
                                    <tr v-for="(item, index) in issuseList.slice(0, 6)" :key="index">
                                        <td>{{ item.issue }}</td>
                                        <td v-for="(is, x) in item.numbers" :key="x">{{ is.num }}</td>
                                    </tr>
                                </table>
                                <table v-else-if="topTitle == 2" border="1" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <th v-for="(item, index) in issuseTopTableTitTwo8" :key="index">{{ item }}</th>
                                    </tr>
                                    <tr v-for="(item, index) in issuseList.slice(0, 6)" :key="index">
                                        <td>{{ item.issue }}</td>
                                        <td v-for="(is, x) in item.numbers" :key="x"
                                            :class="{ 'red': is.ds == getWord('dan'), 'blue': is.ds == getWord('shuang') }">
                                            {{ is.ds }}</td>
                                    </tr>
                                </table>
                                <table v-else-if="topTitle == 3" border="1" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <th v-for="(item, index) in issuseTopTableTitTwo8" :key="index">{{ item }}</th>
                                    </tr>
                                    <tr v-for="(item, index) in issuseList.slice(0, 6)" :key="index">
                                        <td>{{ item.issue }}</td>
                                        <td v-for="(is, x) in item.numbers" :key="x"
                                            :class="{ 'red': is.dx == getWord('da'), 'blue': is.dx == getWord('small') }">
                                            {{ is.dx }}</td>
                                    </tr>
                                </table>
                            </div>

                            <div v-else>
                                <table border="1" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <th v-for="item in drawingHistory.title[gameType]">{{ item }}</th>
                                    </tr>
                                    <tr v-for="item in issuseList">
                                        <template v-if="_TEMPLATE !== 'template-2'">
                                            <td>{{ item.issue }}</td>
                                        </template>
                                        <template v-else>
                                            <td>{{ item.issue.substr(-8) }}</td>
                                        </template>
                                        <td v-for="item in item.numbers">{{ item }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </template>

                    <template v-if="gameType.indexOf('28') > -1">
                        <h3>
                            {{ getWord('zhupanlu') }}
                            <i>（{{ getWord('luzhu_tips') }}）</i>
                        </h3>
                        <div class="luzhu-zhupan">
                            <table class="zhupan">
                                <template v-for="(it, i) in issuseList2">
                                    <tr>
                                        <td v-for="item in it">
                                            <span v-if="currentRule === 'odd'"
                                                :class="{ 'red': item.numbers[3].ds == getWord('dan'), 'blue': item.numbers[3].ds == getWord('shuang') }">{{ item.numbers[3].ds }}</span>
                                            <span v-if="currentRule === 'size'"
                                                :class="{ 'red': item.numbers[3].dx == getWord('da'), 'blue': item.numbers[3].dx == getWord('small') }">{{ item.numbers[3].dx }}</span>
                                        </td>
                                    </tr>
                                </template>
                            </table>
                        </div>
                    </template>
                    <template v-else>
                        <div class="lzBottom">
                            <div class="lzBottomTitle">
                                <span @click="lzNaviCheck(item)" v-for="item in luzhu.title[gameType]"
                                    :class="{ 'check': item.states == buttonTitle }">{{ item.name }}</span>
                            </div>

                            <div class="lzBottomcon">
                                <div class="left">
                                    <template v-for="index in 2">
                                        <span @click="lzConLeftnavi(index - 1)" :class="{ 'check': leftTitle == index - 1 }">
                                            <template v-if="currentLuzhuTabTitle === 'num_longhu'">
                                                <p v-if="index - 1 === 0" class="first">
                                                    <i>龙</i>&nbsp;&nbsp;{{ currentRuleCount.odd }}</p>
                                                <p v-else class="second">
                                                    <i>虎</i>&nbsp;&nbsp;{{ maxResultLength - currentRuleCount.odd - (currentRuleCount.draw) }}
                                                </p>
                                            </template>
                                            <template v-else-if="currentLuzhuTabTitle === 'num_wei'">
                                                <p v-if="index - 1 === 0" class="first">
                                                    <i>大</i>&nbsp;&nbsp;{{ currentRuleCount.odd }}</p>
                                                <p v-else class="second"><i>{{ getWord('small')
                                                }}</i>&nbsp;&nbsp;{{ maxResultLength - currentRuleCount.odd - (currentRuleCount.draw) }}
                                                </p>
                                            </template>
                                            <template v-else>
                                                <template v-if="index - 1 === 0">
                                                    <p><i>{{ getWord('dan') }}</i>&nbsp;&nbsp;{{ currentRuleCount.odd }}
                                                    </p>
                                                    <p><i>{{ getWord('shuang')
                                                    }}</i>&nbsp;&nbsp;{{ maxResultLength - currentRuleCount.odd - (currentRuleCount.draw) }}
                                                    </p>
                                                </template>
                                                <template v-else>
                                                    <p><i>{{ getWord('da') }}</i>&nbsp;&nbsp;{{ currentRuleCount.size }}
                                                    </p>
                                                    <p><i>{{ getWord('small')
                                                    }}</i>&nbsp;&nbsp;{{ maxResultLength - currentRuleCount.size - (currentRuleCount.draw) }}
                                                    </p>
                                                </template>
                                            </template>
                                        </span>
                                    </template>
                                </div>
                                <div class="right" id="trends-luzhu" ref='trendsLuzhu'>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tr v-for="(items, indexs) in currentRuleResult" :key="indexs">
                                            <template v-for="(item, index) in 6">
                                                <td v-if="items[index] === true" :key="index">
                                                    <p class="red">
                                                        {{ currentLuzhuTabTitle === 'num_longhu' ? getWord('dragon') : (currentLuzhuTabTitle === 'num_wei'
                                                                || leftTitle == 1) ? getWord('da') : getWord('dan')
                                                        }}</p>
                                                </td>
                                                <td v-else-if="items[index] === false">
                                                    <p class="blue">
                                                        {{ currentLuzhuTabTitle === 'num_longhu' ? getWord('tiger') : (currentLuzhuTabTitle === 'num_wei'
                                                                || leftTitle == 1) ? getWord('small') : getWord('shuang')
                                                        }}</p>
                                                </td>
                                                <td v-else-if="items[index] === 'draw'">
                                                    <p class="gray">{{ getWord('draw') }}</p>
                                                </td>
                                                <td v-else></td>
                                            </template>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <template v-if="gameType == 'tencent'">
                            <h3 class="clearfix">{{ getWord('zhupanlu') }}</h3>
                            <div class="longhuhe clearfix">
                                <nav>
                                    <a @click="switchTab(item)" v-for="(item, index) in tencent.longhuhe" :key="index"
                                        :class="{ 'check': item.active === true }">{{ item.title }}</a>
                                </nav>

                                <div class="right">

                                    <table border="0" cellpadding="0" cellspacing="0" v-if="tencent.longhuhe[0]">
                                        <tr v-for="(items, indexs) in tencent.longhuhe[this.tencent.longhuhe.findIndex(item => item.active === true)].value.reduce((acc, val, index) => {
                                            if (!index || index % 6 === 0) acc.push([])
                                            acc[acc.length - 1].push(val)
                                            return acc
                                        }, []).slice(0, 14)">
                                            <template v-for="item in items">
                                                <td v-if="item === 1">
                                                    <p class="red">{{ getWord('dragon') }}</p>
                                                </td>
                                                <td v-else-if="item === -1">
                                                    <p class="blue">{{ getWord('tiger') }}</p>
                                                </td>
                                                <td v-else-if="item === 0">
                                                    <p class="gray">{{ getWord('draw') }}</p>
                                                </td>
                                                <td v-else></td>
                                            </template>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </template>
                    </template>

                    <template v-if="gameType.indexOf('28') === -1 && changlongData && changlongData.length">
                        <h3>{{ getWord('changlong3') }}</h3>
                        <ul class="longline">
                            <li v-for="(item, index) in changlongData.filter(item => { return item.value > 2 })" :key="index">
                                <span>{{ changlongRender(item.position, 'position') + '-' +
                                        changlongRender(item.type, 'type')
                                }}</span>
                                <span>{{ item.value + getWord('period') }}</span>
                            </li>
                        </ul>
                    </template>
                </template>
            </template>
        </template>

        <template v-if="currentType === 'odd'">
            <h3>
                {{ getWord('dalu') }}
                <div class="count">
                    <span>{{ getWord('dan') }}</span>
                    <i>{{ currentRuleCount.odd }}</i>
                    <span>{{ getWord('shuang') }}</span>
                    <i>{{ maxResultLength - currentRuleCount.odd }}</i>
                </div>
            </h3>
            <!-- 底部露珠表格 -->
            <div class="lzBottom">

                <div class="lzBottomcon clearfix">

                    <div class="right" id="trends-luzhu" ref='trendsLuzhu'>
                        <table border="0" cellpadding="0" cellspacing="0">
                            <tr v-for="(items, indexs) in currentRuleResult" :key="indexs">
                                <template v-for="(item, index) in 6">
                                    <td v-if="items[index] === true" :key="index">
                                        <p class="red">
                                            {{ currentLuzhuTabTitle === 'num_longhu' ? getWord('long') : (currentLuzhuTabTitle === 'num_wei'
                                                    || leftTitle == 1) ? getWord('da') : getWord('dan')
                                            }}</p>
                                    </td>
                                    <td v-else-if="items[index] === false">
                                        <p class="blue">
                                            {{ currentLuzhuTabTitle === 'num_longhu' ? getWord('hu') : (currentLuzhuTabTitle === 'num_wei'
                                                    || leftTitle == 1) ? getWord('small') : getWord('shuang')
                                            }}</p>
                                    </td>
                                    <td v-else-if="items[index] === 'draw'">
                                        <p class="gray">{{ getWord('draw') }}</p>
                                    </td>
                                    <td v-else></td>
                                </template>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <h3>{{ getWord('zhupanlu') }}</h3>
            <!-- 头部6期开奖历史 -->
            <div class="issuseTop">
                <!-- 单双 -->
                <table border="1" cellpadding="0" cellspacing="0">
                    <template v-for="(it, i) in issuseList2">
                        <tr>
                            <td v-for="item in it">
                                <span
                                    :class="{ 'red': item.numbers[0].ds == getWord('dan'), 'blue': item.numbers[0].ds == getWord('shuang') }">{{ item.numbers[0].ds }}</span>
                            </td>
                        </tr>
                    </template>
                </table>
            </div>
        </template>

        <template v-if="currentType === 'size'">
            <h3>
                {{ getWord('dalu') }}
                <div class="count">
                    <span>{{ getWord('da') }}</span>
                    <i>{{ currentRuleCount.size }}</i>
                    <span>{{ getWord('small') }}</span>
                    <i>{{ maxResultLength - currentRuleCount.size }}</i>
                </div>
            </h3>
            <!-- 底部露珠表格 -->
            <div class="lzBottom">

                <div class="lzBottomcon clearfix">

                    <div class="right" id="trends-luzhu" ref='trendsLuzhu'>
                        <table border="0" cellpadding="0" cellspacing="0">
                            <tr v-for="(items, indexs) in currentRuleResult" :key="indexs">
                                <template v-for="(item, index) in 6">
                                    <td v-if="items[index] === true" :key="index">
                                        <p class="red">
                                            {{ currentLuzhuTabTitle === 'num_longhu' ? getWord('long') : (currentLuzhuTabTitle === 'num_wei'
                                                    || leftTitle == 1) ? getWord('da') : getWord('dan')
                                            }}</p>
                                    </td>
                                    <td v-else-if="items[index] === false">
                                        <p class="blue">
                                            {{ currentLuzhuTabTitle === 'num_longhu' ? getWord('hu') : (currentLuzhuTabTitle === 'num_wei'
                                                    || leftTitle == 1) ? getWord('small') : getWord('shuang')
                                            }}</p>
                                    </td>
                                    <td v-else-if="items[index] === 'draw'">
                                        <p class="gray">{{ getWord('draw') }}</p>
                                    </td>
                                    <td v-else></td>
                                </template>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <h3>{{ getWord('zhupanlu') }}</h3>
            <div class="issuseTop">
                <!-- 大小 -->
                <table border="1" cellpadding="0" cellspacing="0">
                    <template v-for="(it, i) in issuseList2">
                        <tr>
                            <td v-for="item in it">
                                <span
                                    :class="{ 'red': item.numbers[0].dx == getWord('da'), 'blue': item.numbers[0].dx == getWord('small') }">{{ item.numbers[0].dx }}</span>
                            </td>
                        </tr>
                    </template>
                </table>
            </div>
        </template>
    </div>
</template>
<style lang='scss' scoped>
@import "@@/assets/style/_variables";

#trends-by-luzhu {
    overflow: auto;
    height: 100%;

    &.template-3 {
        background-color: #EEEEEE;

        #switcher {
            background-color: #ffffff;
            flex-wrap: nowrap;

            li {
                font-size: .3rem;
                padding-left: .3rem;
                padding-right: .3rem;

                &.active {
                    color: #EC2829;
                    border-bottom: 1px solid #EC2829;
                }
            }
        }

        h3 {
            padding: .15rem .25rem;
            font-size: .3rem;
            color: #FF0F0F;

            &:before {
                content: none;
            }

            i {
                font-style: normal;
                font-size: .24rem;
                color: #B0B0B0;
            }

            .count {
                float: right;
                font-weight: normal;

                span {
                    display: inline-block;
                    width: .52rem;
                    height: .52rem;
                    line-height: .52rem;
                    text-align: center;
                    color: #fff;
                    font-size: .3rem;
                    border-radius: 100%;
                    vertical-align: middle;

                    &:nth-child(1) {
                        background-color: #FF0F0F;
                    }

                    &:nth-child(3) {
                        background-color: $TEMPLATE2-theme-color;
                    }
                }

                i {
                    vertical-align: middle;
                    font-size: .3rem;
                    font-style: normal;
                    margin: 0 .15rem;

                    &:nth-child(2) {
                        color: #FF0F0F;
                    }

                    &:nth-child(4) {
                        color: $TEMPLATE2-theme-color;
                    }
                }
            }
        }

        .luzhu-zhupan {
            min-height: 3rem;
            margin-bottom: 0;
            background-color: #ffffff;

            >div {
                overflow: auto;
            }

            table.zhupan {
                width: auto;
                transform: rotateZ(90deg) rotateX(180deg);
                transform-origin: 1rem 1rem;

                tr {

                    td {
                        transform: rotateZ(270deg) rotateY(180deg);
                        padding: 0;
                        border-color: #d0d0d0;

                        &:nth-of-type(1) {
                            text-align: center;
                            text-indent: 0;
                        }

                        span {
                            display: inline-block;
                            font-size: .3rem;
                            width: .52rem;
                            height: .52rem;
                            line-height: .52rem;
                            vertical-align: middle;
                            border-radius: 100%;
                            margin: 1px;

                            &.red {
                                background: #FF0F0F;
                                color: #fff;
                            }

                            &.blue {
                                background: $TEMPLATE2-theme-color;
                                color: #fff;
                            }
                        }


                    }


                }
            }

            .issuseTopNavi {
                width: 100%;
                height: 0.6rem;
                line-height: 0.6rem;
                font-size: 0.32rem;

                span {
                    width: 33.3%;
                    text-align: center;
                    float: left;
                    height: 0.55rem;

                    &:last-child {
                        width: 33.4%;
                    }
                }

                .check {
                    border-bottom: 2px solid #3985E1;
                    color: #3985E1;
                }
            }
        }

        .luzhu-da {
            clear: both;
            margin-bottom: 0;
            background-color: #ffffff;

            .lzBottomcon {
                height: 3.8rem;

                .right {
                    width: 100%;

                    table tr {

                        td {
                            min-width: .6rem;
                            min-height: .6rem;
                            border-color: #d0d0d0;

                            p {
                                color: transparent;
                                background-color: transparent;
                                width: .52rem;
                                height: .52rem;
                                border: 1.5px solid #252525;
                            }

                            .red {
                                border-color: #FF0F0F;
                            }

                            .blue {
                                border-color: $TEMPLATE2-theme-color;
                            }
                        }
                    }
                }
            }
        }

        ul {

            li {

                &.highlight {
                    color: #EC2829;
                    border-color: #EC2829;
                }
            }

            &.longline {

                li {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    padding: .1rem .25rem;
                    font-size: .28rem;

                    &:nth-child(odd) {
                        background-color: #FFFFFF;
                    }
                }
            }
        }

        .lzBottom {
            margin: .25rem 0;
            background-color: #ffffff;

            .lzBottomTitle {

                span {
                    font-size: .3rem;
                    border: 0;
                    padding: .1rem 0;

                    &.check {
                        color: #FF0F0F;
                        border-bottom: 2px solid #EC2829;
                    }
                }
            }

            .lzBottomcon {

                .left {

                    span {

                        p {
                            color: #666666;
                            padding: 0.25rem 0 0 .25rem;

                            i {
                                color: #FF0F0F;
                                font-style: normal;
                                display: inline-block;
                                width: .56rem;
                                height: .56rem;
                                line-height: .56rem;
                                margin-right: .05rem;
                                text-align: center;
                                border-radius: 100%;
                                background-color: #ffffff;
                                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
                            }

                            &:last-child {
                                padding-top: .1rem;

                                i {
                                    color: $TEMPLATE2-theme-color;
                                }
                            }
                        }

                        &.check {
                            background-color: #EDB93F;

                            p {
                                color: #ffffff;
                            }
                        }
                    }
                }

                .right {

                    table {

                        tr {

                            td {
                                border-color: #f5f5f9;

                                p {
                                    font-size: .28rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        .luzhu-list {

            tr {

                th {
                    font-size: .28rem;
                    color: #EC2829;
                }
            }
        }
    }

    &.template-2 {

        >h3 {
            display: none;
        }

        ul {

            li {
                font-size: .3rem;
                border: 0;

                &.highlight {
                    color: #FF0F0F;
                    border-bottom-color: transparent;
                }
            }
        }

        .luzhu-zhupan {

            table {
                border-bottom: 0;

                tr {

                    .red {
                        background-color: transparent;
                        color: #FF0F0F;
                    }

                    .blue {
                        background-color: transparent;
                        color: $TEMPLATE2-theme-color;
                    }

                    th,
                    td {
                        border: 0;
                    }

                    th {
                        background-color: #F5F5F9;
                        vertical-align: bottom;
                        width: auto;

                        span {

                            &.non-chinese {
                                writing-mode: vertical-rl;
                            }
                        }

                        &:first-child {
                            text-align: center;
                            border-right: 1px solid #CCCCCC;
                        }
                    }

                    td {

                        &:first-child {
                            border-right: 1px solid #CCCCCC;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .luzhu-da {
            margin-bottom: 0;

            .lzBottomTitle {
                display: flex;
                flex-wrap: wrap;

                span {
                    font-size: .3rem;
                    border: 0;
                    height: auto;

                    &.check {
                        height: auto;
                        color: #FF0F0F;
                        border-bottom-color: transparent;
                    }
                }
            }

            .lzBottomcon {
                height: 3.6rem;
                overflow: hidden;

                .left {
                    float: left;
                    width: 20%;

                    span {
                        font-size: 0.28rem;
                        border-bottom: 1px solid #f3f3f4;
                        box-sizing: border-box;
                        display: block;
                        height: 1.75rem;

                        p {
                            color: #E83535;
                            padding: 0.35rem 0 0 .4rem;

                            &:nth-child(2),
                            &.second {
                                color: #4796FC;
                                padding-top: .15rem;
                            }

                            &.first,
                            &.second {
                                padding-top: .66rem;
                            }
                        }

                        &:nth-child(2) {
                            padding-top: 2px;
                        }
                    }

                    .check {
                        background: rgba(57, 133, 225, 0.1);
                    }
                }

                .right {
                    width: 80%;
                    min-height: 100%;
                    overflow: auto;

                    table {
                        border: 0;
                        transform-origin: 1rem 1rem;
                        transform: rotateZ(90deg) rotateX(180deg);
                    }

                    table tr {

                        &:last-child {
                            border-right: 1px solid #eee;
                        }

                        td {
                            border: 1px solid #f3f3f4;
                            min-width: .56rem;
                            min-height: .56rem;
                            transform: rotateZ(90deg) rotateX(180deg);

                            p {
                                color: #FFF;
                                border-radius: 100%;
                                width: .56rem;
                                height: .56rem;
                                line-height: .56rem;
                                text-align: center;
                            }

                            .red {
                                background: #E83535;
                            }

                            .blue {
                                background: #3985E1;
                            }

                            .gray {
                                background: #999999;
                            }
                        }
                    }
                }
            }

            .lzBottomcon {
                clear: both;

                .left {

                    span {

                        p {
                            color: #666666;
                            padding: 0.25rem 0 0 .25rem;

                            i {
                                color: #FF0F0F;
                                font-style: normal;
                                display: inline-block;
                                width: .56rem;
                                height: .56rem;
                                line-height: .56rem;
                                margin-right: .05rem;
                                text-align: center;
                                border-radius: 100%;
                                background-color: #ffffff;
                                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
                            }

                            &:last-child {
                                padding-top: .1rem;

                                i {
                                    color: $TEMPLATE2-theme-color;
                                }
                            }
                        }

                        &.check {
                            background-color: #F5F5F9;
                        }
                    }
                }

                .right {

                    table {

                        tr {

                            td {
                                border-color: #f5f5f9;

                                p {
                                    font-size: .28rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        .longline {

            h3 {
                float: none;
                margin-bottom: .2rem;
                color: #FF0F0F;
                padding-left: .75rem;

                &:before {
                    content: none;
                }
            }

            ul {

                li {
                    display: flex;
                    justify-content: space-between;
                    height: 0.7rem;
                    line-height: 0.7rem;
                    font-size: 0.28rem;
                    width: 83%;
                    padding: 0 12% 0 5%;

                    &:nth-child(2n-1) {
                        background: #ECECEC;
                    }

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3) {
                        &>span:last-child {
                            color: red;
                        }
                    }
                }
            }
        }

        .lzBottom {

            .lzBottomTitle {

                span {
                    font-size: .3rem;
                    border: 0;

                    &.check {
                        color: #FF0F0F;
                        border-bottom-color: transparent;
                    }
                }
            }

            .lzBottomcon {

                .left {

                    span {

                        p {
                            color: #666666;
                            padding: 0.25rem 0 0 .25rem;

                            i {
                                color: #FF0F0F;
                                font-style: normal;
                                display: inline-block;
                                width: .56rem;
                                height: .56rem;
                                line-height: .56rem;
                                margin-right: .05rem;
                                text-align: center;
                                border-radius: 100%;
                                background-color: #ffffff;
                                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
                            }

                            &:last-child {
                                padding-top: .1rem;

                                i {
                                    color: $TEMPLATE2-theme-color;
                                }
                            }
                        }

                        &.check {
                            background-color: #F5F5F9;
                        }
                    }
                }

                .right {

                    table {

                        tr {

                            td {
                                border-color: #f5f5f9;

                                p {
                                    font-size: .28rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.template-1 {

        >h3 {
            display: none;
        }

        ul {

            li {
                font-size: .3rem;
                border: 0;

                &.highlight {
                    color: #FF0F0F;
                    border-bottom-color: transparent;
                }
            }
        }

        .luzhu-zhupan {

            table {
                border-bottom: 0;

                tr {

                    .red {
                        background-color: transparent;
                        color: #FF0F0F;
                    }

                    .blue {
                        background-color: transparent;
                        color: $TEMPLATE2-theme-color;
                    }

                    th,
                    td {
                        border: 0;
                    }

                    th {
                        background-color: #F5F5F9;
                        vertical-align: bottom;
                        width: auto;

                        span {

                            &.non-chinese {
                                writing-mode: vertical-rl;
                            }
                        }

                        &:first-child {
                            text-align: center;
                            border-right: 1px solid #CCCCCC;
                        }
                    }

                    td {

                        &:first-child {
                            border-right: 1px solid #CCCCCC;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .luzhu-da {
            margin-bottom: 0;

            .lzBottomTitle {
                display: flex;
                flex-wrap: wrap;

                span {
                    font-size: .3rem;
                    border: 0;
                    height: auto;

                    &.check {
                        height: auto;
                        color: #FF0F0F;
                        border-bottom-color: transparent;
                    }
                }
            }

            .lzBottomcon {
                height: 3.6rem;
                overflow: hidden;

                .left {
                    float: left;
                    width: 20%;

                    span {
                        font-size: 0.28rem;
                        border-bottom: 1px solid #f3f3f4;
                        box-sizing: border-box;
                        display: block;
                        height: 1.75rem;

                        p {
                            color: #E83535;
                            padding: 0.35rem 0 0 .4rem;

                            &:nth-child(2),
                            &.second {
                                color: #4796FC;
                                padding-top: .15rem;
                            }

                            &.first,
                            &.second {
                                padding-top: .66rem;
                            }
                        }

                        &:nth-child(2) {
                            padding-top: 2px;
                        }
                    }

                    .check {
                        background: rgba(57, 133, 225, 0.1);
                    }
                }

                .right {
                    width: 80%;
                    min-height: 100%;
                    overflow: auto;

                    table {
                        border: 0;
                        transform-origin: 1rem 1rem;
                        transform: rotateZ(90deg) rotateX(180deg);
                    }

                    table tr {

                        &:last-child {
                            border-right: 1px solid #eee;
                        }

                        td {
                            border: 1px solid #f3f3f4;
                            min-width: .56rem;
                            min-height: .56rem;
                            transform: rotateZ(90deg) rotateX(180deg);

                            p {
                                color: #FFF;
                                border-radius: 100%;
                                width: .56rem;
                                height: .56rem;
                                line-height: .56rem;
                                text-align: center;
                            }

                            .red {
                                background: #E83535;
                            }

                            .blue {
                                background: #3985E1;
                            }

                            .gray {
                                background: #999999;
                            }
                        }
                    }
                }
            }

            .lzBottomcon {
                clear: both;

                .left {

                    span {

                        p {
                            color: #666666;
                            padding: 0.25rem 0 0 .25rem;

                            i {
                                color: #FF0F0F;
                                font-style: normal;
                                display: inline-block;
                                width: .56rem;
                                height: .56rem;
                                line-height: .56rem;
                                margin-right: .05rem;
                                text-align: center;
                                border-radius: 100%;
                                background-color: #ffffff;
                                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
                            }

                            &:last-child {
                                padding-top: .1rem;

                                i {
                                    color: $TEMPLATE2-theme-color;
                                }
                            }
                        }

                        &.check {
                            background-color: #F5F5F9;
                        }
                    }
                }

                .right {

                    table {

                        tr {

                            td {
                                border-color: #f5f5f9;

                                p {
                                    font-size: .28rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        .longline {

            h3 {
                float: none;
                margin-bottom: .2rem;
                color: #FF0F0F;
                padding-left: .75rem;

                &:before {
                    content: none;
                }
            }

            ul {

                li {
                    display: flex;
                    justify-content: space-between;
                    height: 0.7rem;
                    line-height: 0.7rem;
                    font-size: 0.28rem;
                    width: 83%;
                    padding: 0 12% 0 5%;

                    &:nth-child(2n-1) {
                        background: #ECECEC;
                    }

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3) {
                        &>span:last-child {
                            color: red;
                        }
                    }
                }
            }
        }

        .lzBottom {

            .lzBottomTitle {

                span {
                    font-size: .3rem;
                    border: 0;

                    &.check {
                        color: #FF0F0F;
                        border-bottom-color: transparent;
                    }
                }
            }

            .lzBottomcon {

                .left {

                    span {

                        p {
                            color: #666666;
                            padding: 0.25rem 0 0 .25rem;

                            i {
                                color: #FF0F0F;
                                font-style: normal;
                                display: inline-block;
                                width: .56rem;
                                height: .56rem;
                                line-height: .56rem;
                                margin-right: .05rem;
                                text-align: center;
                                border-radius: 100%;
                                background-color: #ffffff;
                                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
                            }

                            &:last-child {
                                padding-top: .1rem;

                                i {
                                    color: $TEMPLATE2-theme-color;
                                }
                            }
                        }

                        &.check {
                            background-color: #F5F5F9;
                        }
                    }
                }

                .right {

                    table {

                        tr {

                            td {
                                border-color: #f5f5f9;

                                p {
                                    font-size: .28rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ul {
        display: flex;
        justify-content: space-around;
        list-style: none;
        flex-wrap: wrap;

        li {
            color: #666666;
            font-size: .34rem;
            padding: .2rem;
            border-bottom: 2px solid transparent;

            &.highlight {
                color: #3985E1;
                border-bottom-color: #3985E1;
            }
        }
    }

    h3 {
        padding: .25rem .25rem 0 .25rem;
        font-size: .32rem;
        font-weight: 500;
        color: #333333;

        &:before {
            content: '';
            width: 3px;
            background-color: #0F417D;
            height: .32rem;
            display: inline-block;
            vertical-align: middle;
            margin-right: .125rem;
            border-radius: 1rem;
        }
    }

    table,
    table tr th,
    table tr td {
        border: 1px solid #eee;
    }

    table {
        border-collapse: collapse;
    }

    .luzhu-zhupan {
        width: 100%;
        margin-bottom: .25rem;

        table {
            width: 100%;
            text-align: center;
            font-size: 0.28rem;

            tr {

                th {
                    color: #999999;
                    padding: 0.1rem 0;
                    background: #EAEAEA;
                    width: 0.5rem;
                    font-weight: normal;

                    &:nth-of-type(1) {
                        // text-align: left;
                        // text-indent: 0.1rem;
                    }
                }

                td {
                    color: #666;
                    padding: 0.1rem 0;

                    &:nth-of-type(1) {
                        text-align: left;
                        text-indent: 0.2rem;
                    }
                }

                .red {
                    background: #E83535;
                    color: #fff;
                }

                .blue {
                    background: #3985E1;
                    color: #fff;
                }
            }
        }

        .issuseTopNavi {
            width: 100%;
            height: 0.6rem;
            line-height: 0.6rem;
            font-size: 0.32rem;

            span {
                width: 33.3%;
                text-align: center;
                float: left;
                height: 0.55rem;

                &:last-child {
                    width: 33.4%;
                }
            }

            .check {
                border-bottom: 2px solid #3985E1;
                color: #3985E1;
            }
        }
    }

    .luzhu-da {
        margin-bottom: .25rem;

        .lzBottomTitle {
            width: 100%;
            height: auto;

            span {
                text-align: center;
                float: left;
                height: 0.5rem;
                line-height: 0.5rem;
                width: 20%;
            }

            .check {
                height: 0.46rem;
                border-bottom: 2px solid #3985E1;
                color: #3985E1;
            }
        }

        .lzBottomcon {
            height: 3.6rem;
            overflow: hidden;

            .left {
                float: left;
                width: 20%;

                span {
                    font-size: 0.28rem;
                    border-bottom: 1px solid #f3f3f4;
                    box-sizing: border-box;
                    display: block;
                    height: 1.75rem;

                    p {
                        color: #E83535;
                        padding: 0.35rem 0 0 .4rem;

                        &:last-child {
                            color: #4796FC;
                            padding-top: .15rem;
                        }
                    }

                    &:nth-child(2) {
                        padding-top: 2px;
                    }
                }

                .check {
                    background: rgba(57, 133, 225, 0.1);
                }
            }

            .right {
                width: 80%;
                min-height: 100%;
                overflow: auto;

                table {
                    border: 0;
                    transform-origin: 1rem 1rem;
                    transform: rotateZ(90deg) rotateX(180deg);
                }

                table tr {

                    &:last-child {
                        border-right: 1px solid #eee;
                    }

                    td {
                        border: 1px solid #f3f3f4;
                        min-width: .56rem;
                        min-height: .56rem;
                        transform: rotateZ(90deg) rotateX(180deg);

                        p {
                            color: #FFF;
                            border-radius: 100%;
                            width: .56rem;
                            height: .56rem;
                            line-height: .56rem;
                            text-align: center;
                        }

                        .red {
                            background: #E83535;
                        }

                        .blue {
                            background: #3985E1;
                        }
                    }
                }
            }
        }
    }

    .mainFG {
        width: 100%;
        height: 0.1rem;
        // float: left;
        margin: .5rem 0;
    }

    .listTable {
        height: 0.7rem;
        line-height: 0.7rem;
        font-size: 0.28rem;
        width: 83%;
        padding: 0 12% 0 5%;

        &:nth-child(1) {
            margin-top: 0.2rem;
        }

        &:nth-child(2n-1) {
            background: #ECECEC;
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            &>span:last-child {
                color: red;
            }
        }
    }

    .lzBottom {
        width: 100%;
        height: auto;
        float: left;

        .lzBottomTitle {
            width: 100%;
            height: auto;
            display: block;
            overflow: hidden;

            span {
                text-align: center;
                line-height: 0.6rem;
                width: 20%;
                height: 0.6rem;
                display: inline-block;
                white-space: nowrap;
            }

            .check {
                height: 0.54rem;
                border-bottom: 2px solid #3985E1;
                color: #3985E1;
                display: inline-block;
            }
        }

        .lzBottomcon {
            height: 3.6rem;
            overflow: hidden;

            .left {
                float: left;
                width: 20%;

                span {
                    font-size: 0.28rem;
                    border-bottom: 1px solid #f3f3f4;
                    box-sizing: border-box;
                    display: block;
                    height: 1.75rem;

                    p {
                        color: #E83535;
                        padding: 0.35rem 0 0 .4rem;

                        &:nth-child(2),
                        &.second {
                            color: #4796FC;
                            padding-top: .15rem;
                        }

                        &.first,
                        &.second {
                            padding-top: .66rem;
                        }
                    }

                    &:nth-child(2) {
                        padding-top: 2px;
                    }
                }

                .check {
                    background: rgba(57, 133, 225, 0.1);
                }
            }

            .right {
                width: 80%;
                min-height: 100%;
                overflow: auto;

                table {
                    border: 0;
                    transform-origin: 1rem 1rem;
                    transform: rotateZ(90deg) rotateX(180deg);
                }

                table tr {

                    &:last-child {
                        border-right: 1px solid #eee;
                    }

                    td {
                        border: 1px solid #f3f3f4;
                        min-width: .56rem;
                        min-height: .56rem;
                        transform: rotateZ(90deg) rotateX(180deg);

                        p {
                            color: #FFF;
                            border-radius: 100%;
                            width: .56rem;
                            height: .56rem;
                            line-height: .56rem;
                            text-align: center;
                        }

                        .red {
                            background: #E83535;
                        }

                        .blue {
                            background: #3985E1;
                        }

                        .gray {
                            background: #999999;
                        }
                    }
                }
            }
        }
    }

    .luzhu-list {
        float: left;
        width: 100%;
        font-size: 0.3rem;
        text-align: center;
        background: #FFF;
        border: none;

        &.kuai3 {

            tr {

                td {

                    .dice {
                        font-size: .48rem;
                        vertical-align: -.1rem;
                        margin-left: .2rem;
                    }
                }
            }
        }

        tr {
            font-size: 0.22rem;
            color: #999;
            height: 0.8rem;
            line-height: 0.8rem;

            th {
                font-weight: normal;
                text-align: center;
                background: #EAEAEA;

                &:nth-child(1) {
                    text-indent: 0.2rem;
                }

                &:nth-child(3),
                &:nth-child(4) {
                    text-align: center;
                }
            }

            td {
                color: #000;
                height: 1rem;
                line-height: 0.5rem;
                font-size: 0.3rem;
                border-bottom: 1px solid #D9D9D9;
                border-left: 0;
                border-right: 0;

                &:nth-child(1) {
                    width: 28%;
                    text-align: left;
                    text-indent: 0.2rem;
                }

                &:nth-child(2) {
                    width: 32%;
                    text-align: left;
                }
            }
        }
    }

    .longhuhe {
        margin-bottom: .5rem;

        nav {

            a {
                float: left;
                width: 20%;
                text-align: center;
                font-size: .24rem;
                height: 0.6rem;
                line-height: .6rem;
                border-bottom: 2px solid transparent;

                &.check {
                    border-bottom: 2px solid #3985E1;
                    color: #3985E1;
                    display: inline-block;
                }
            }
        }

        .right {
            width: 100%;
            height: 3.5rem;
            overflow: auto;

            table {
                border: 0;
                transform-origin: 1rem 1rem;
                transform: rotateZ(90deg) rotateX(180deg);
            }

            table tr {

                &:last-child {
                    border-right: 1px solid #eee;
                }

                td {
                    border: 1px solid #f3f3f4;
                    min-width: .56rem;
                    min-height: .56rem;
                    transform: rotateZ(90deg) rotateX(180deg);

                    p {
                        color: #FFF;
                        border-radius: 100%;
                        width: .56rem;
                        height: .56rem;
                        line-height: .56rem;
                        text-align: center;
                    }

                    .red {
                        background: #E83535;
                    }

                    .blue {
                        background: #3985E1;
                    }

                    .gray {
                        background: #999999;
                    }
                }
            }
        }
    }
}

#trends-by-luzhu-odd {
    overflow: auto;
    height: 100%;

    ul {
        display: flex;
        justify-content: space-around;
        list-style: none;
        flex-wrap: wrap;

        li {
            color: #666666;
            font-size: .34rem;
            padding: .2rem;
            border-bottom: 2px solid transparent;

            &.highlight {
                color: #3985E1;
                border-bottom-color: #3985E1;
            }
        }
    }

    h3 {
        padding: .25rem;
        font-size: .36rem;
        color: #252525;

        .count {
            float: right;
            font-weight: normal;

            span {
                display: inline-block;
                width: .48rem;
                height: .48rem;
                line-height: .48rem;
                text-align: center;
                color: #fff;
                font-size: .28rem;
                border-radius: 100%;
                vertical-align: middle;

                &:nth-child(1) {
                    background-color: #FF0F0F;
                }

                &:nth-child(3) {
                    background-color: $TEMPLATE2-theme-color;
                }
            }

            i {
                font-style: normal;
                margin: 0 .15rem;

                &:nth-child(2) {
                    color: #FF0F0F;
                }

                &:nth-child(4) {
                    color: $TEMPLATE2-theme-color;
                }
            }
        }
    }

    table,
    table tr th,
    table tr td {
        border: 1px solid #eee;
    }

    table {
        border-collapse: collapse;
    }

    .issuseTop {
        width: 100%;
        margin-bottom: .25rem;

        table {
            text-align: center;
            font-size: 0.28rem;
            transform: rotateZ(90deg) rotateX(180deg);
            transform-origin: 1rem 1rem;

            tr {
                th {
                    color: #999999;
                    padding: 0.1rem 0;
                    background: #EAEAEA;
                    width: 0.5rem;
                    font-weight: normal;


                }

                td {
                    transform: rotateZ(270deg) rotateY(180deg);

                    span {
                        display: inline-block;
                        font-size: .2rem;
                        width: .34rem;
                        height: .34rem;
                        line-height: .34rem;
                        vertical-align: middle;
                        border-radius: 100%;
                        margin: 1px;

                        &.red {
                            background: #FF0F0F;
                            color: #fff;
                        }

                        &.blue {
                            background: $TEMPLATE2-theme-color;
                            color: #fff;
                        }
                    }


                }


            }
        }

        .issuseTopNavi {
            width: 100%;
            height: 0.6rem;
            line-height: 0.6rem;
            font-size: 0.32rem;

            span {
                width: 33.3%;
                text-align: center;
                float: left;
                height: 0.55rem;

                &:last-child {
                    width: 33.4%;
                }
            }

            .check {
                border-bottom: 2px solid #3985E1;
                color: #3985E1;
            }
        }
    }

    .lzBottom {
        margin-bottom: .25rem;
        margin-left: .25rem;

        .lzBottomcon {
            height: 2.8rem;
            overflow: hidden;

            .right {
                min-height: 100%;
                overflow: auto;

                table {
                    border: 0;
                    transform-origin: 1rem 1rem;
                    transform: rotateZ(90deg) rotateX(180deg);
                }

                table tr {

                    &:last-child {
                        border-right: 1px solid #eee;
                    }

                    td {
                        border: 1px solid #f5f5f9;
                        min-width: .34rem;
                        min-height: .34rem;
                        transform: rotateZ(90deg) rotateX(180deg);

                        p {
                            color: transparent;
                            border-radius: 100%;
                            width: .34rem;
                            height: .34rem;
                            line-height: .56rem;
                            text-align: center;
                            border: 1.5px solid #252525;
                        }

                        .red {
                            border-color: #FF0F0F;
                        }

                        .blue {
                            border-color: $TEMPLATE2-theme-color;
                        }
                    }
                }
            }
        }
    }
}

#trends-by-luzhu-size {
    overflow: auto;
    height: 100%;

    ul {
        display: flex;
        justify-content: space-around;
        list-style: none;
        flex-wrap: wrap;

        li {
            color: #666666;
            font-size: .34rem;
            padding: .2rem;
            border-bottom: 2px solid transparent;

            &.highlight {
                color: #3985E1;
                border-bottom-color: #3985E1;
            }
        }
    }

    h3 {
        padding: .25rem;
        font-size: .36rem;
        color: #252525;

        .count {
            float: right;
            font-weight: normal;

            span {
                display: inline-block;
                width: .48rem;
                height: .48rem;
                line-height: .48rem;
                text-align: center;
                color: #fff;
                font-size: .28rem;
                border-radius: 100%;
                vertical-align: middle;

                &:nth-child(1) {
                    background-color: #FF0F0F;
                }

                &:nth-child(3) {
                    background-color: $TEMPLATE2-theme-color;
                }
            }

            i {
                font-style: normal;
                margin: 0 .15rem;

                &:nth-child(2) {
                    color: #FF0F0F;
                }

                &:nth-child(4) {
                    color: $TEMPLATE2-theme-color;
                }
            }
        }
    }

    table,
    table tr th,
    table tr td {
        border: 1px solid #eee;
    }

    table {
        border-collapse: collapse;
    }

    .issuseTop {
        width: 100%;
        margin-bottom: .25rem;

        table {
            text-align: center;
            font-size: 0.28rem;
            transform: rotateZ(90deg) rotateX(180deg);
            transform-origin: 1rem 1rem;

            tr {
                th {
                    color: #999999;
                    padding: 0.1rem 0;
                    background: #EAEAEA;
                    width: 0.5rem;
                    font-weight: normal;


                }

                td {
                    transform: rotateZ(270deg) rotateY(180deg);

                    span {
                        display: inline-block;
                        font-size: .2rem;
                        width: .34rem;
                        height: .34rem;
                        line-height: .34rem;
                        vertical-align: middle;
                        border-radius: 100%;
                        margin: 1px;

                        &.red {
                            background: #FF0F0F;
                            color: #fff;
                        }

                        &.blue {
                            background: $TEMPLATE2-theme-color;
                            color: #fff;
                        }
                    }


                }


            }
        }

        .issuseTopNavi {
            width: 100%;
            height: 0.6rem;
            line-height: 0.6rem;
            font-size: 0.32rem;

            span {
                width: 33.3%;
                text-align: center;
                float: left;
                height: 0.55rem;

                &:last-child {
                    width: 33.4%;
                }
            }

            .check {
                border-bottom: 2px solid #3985E1;
                color: #3985E1;
            }
        }
    }

    .lzBottom {
        margin-bottom: .25rem;
        margin-left: .25rem;

        .lzBottomcon {
            height: 2.8rem;
            overflow: hidden;

            .right {
                min-height: 100%;
                overflow: auto;

                table {
                    border: 0;
                    transform-origin: 1rem 1rem;
                    transform: rotateZ(90deg) rotateX(180deg);
                }

                table tr {

                    &:last-child {
                        border-right: 1px solid #eee;
                    }

                    td {
                        border: 1px solid #f5f5f9;
                        min-width: .34rem;
                        min-height: .34rem;
                        transform: rotateZ(90deg) rotateX(180deg);

                        p {
                            color: transparent;
                            border-radius: 100%;
                            width: .34rem;
                            height: .34rem;
                            line-height: .56rem;
                            text-align: center;
                            border: 1.5px solid #252525;
                        }

                        .red {
                            border-color: #FF0F0F;
                        }

                        .blue {
                            border-color: $TEMPLATE2-theme-color;
                        }
                    }
                }
            }
        }
    }
}
</style>